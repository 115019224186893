// /components/CurrencyProvider.js
import React, { createContext, useContext, useEffect, useState } from 'react'

const CurrencyContext = createContext()

export const CurrencyProvider = ({ children }) => {
	const [exchangeRate, setExchangeRate] = useState(3.67) // Default value for AED/USD
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const fetchExchangeRate = async () => {
			try {
				const response = await fetch(
					'https://api.exchangerate-api.com/v4/latest/USD'
				)
				const data = await response.json()
				setExchangeRate(data.rates.AED)
				setLoading(false)
			} catch (error) {
				console.error('Error fetching exchange rate:', error)
			}
		}

		fetchExchangeRate()
	}, [])

	return (
		<CurrencyContext.Provider value={{ exchangeRate, loading }}>
			{children}
		</CurrencyContext.Provider>
	)
}

export const useCurrency = () => useContext(CurrencyContext)
