import './src/styles/global.css'
import React from 'react'
import Layout from './src/layout/Layout'
import { CurrencyProvider } from './src/components/CurrencyProvider'

export const wrapPageElement = ({ element, props }) => {
	return (
		<CurrencyProvider>
			<Layout {...props}>{element}</Layout>
		</CurrencyProvider>
	)
}
