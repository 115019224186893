import * as React from 'react'
import { Slice } from 'gatsby'
// import Approved from '../components/Approved'

const Layout = ({ children }) => {
	return (
		<>
			<Slice alias='header' />
			<main>{children}</main>
			{/* <Approved /> */}
			<Slice alias='footer' />
		</>
	)
}

export default Layout
